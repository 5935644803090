.home {
  background-color: white;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  .homeHeader {
    display: flex;
    justify-content: center;
  }
  .laptopBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    align-self: flex-end;
    padding: 2rem 0rem;
  }
  .mouseImg {
    width: 90vw;
  }
  .mouseImgBlock {
    margin-top: 4rem;

    position: relative;
  }
  .chat {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.7rem;
    padding: 0.2rem 0.9rem;
    @media screen and (max-width: 768px) {
      position: static;
      transform: translateX(0px);
      margin: auto;
      text-align: center;
      justify-content: center;
      width: 100vw;
    }
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 40rem;

    .clientChat {
      width: 5rem;
    }
    .clientTitle {
      color: #2b55a1;
      font-weight: 600;
      @media screen and (max-width: 768px) {
        font-size: 1.5rem;
      }
    }
  }
  // .clientChat {
  //   width: 40rem;
  // position: absolute;
  // bottom: 0;
  // left: 50%;
  // transform: translateX(-50%);
  // }
  .cardBlockContainer {
    margin-top: 4rem;
    margin-bottom: 5rem;
  }
  .footerBlock {
    margin-top: 5rem;
  }
}

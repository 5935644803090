.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0rem 2rem;
  align-items: center;
  .mobileInner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 2rem;
    .firstRow {
     
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logoContainer {
        width: 7.5rem;
        height: 8rem;
        img {
          width: 6rem;
        }
      }
    }
    .secondRow {
      align-self: flex-end;
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 1rem;
    & > :not(:first-child) {
      margin-top: 1rem;
    }
    .logoContainer {
      order: 1;
    }
  }
  @media screen and (min-width: 769px) {
    position: relative;
    height: 7rem;
  }
  .navLinks {
    display: flex;
    padding: 1rem;
    // align-self: flex-start;
    align-items: flex-start;
    .navLinkText {
      font-weight: 400;
      color: #191b1f;
    }
    @media screen and (min-width: 769px) {
      position: absolute;
      left: 4rem;
    }

    & a:not(:first-child) {
      margin-left: 2rem !important;
    }
    a {
      display: flex;
      align-items: center;
      line-height: 50%;
      //   font-size: 11px;
      font-family: "Poppins", sans-serif;
      color: #191b1f !important;
      padding-right: 0px;
      font-size: 1rem;
      font-weight: 400;
      &:hover {
        color: #2b55a1;
      }
    }
  }
  .logoContainer {
    width: 180px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 4px 25px 5px rgba(133, 132, 132, 0.25);
    border-radius: 32px;
    padding: 1rem;
    @media screen and (max-width: 768px) {
      align-self: flex-start;
      order: 1;
    }
    @media screen and (max-width: 468px) {
      display: flex;
      align-items: center;
      width: 15rem !important;
    }
    @media screen and (min-width: 769px) {
      // margin-top: -1rem;
      position: absolute;
      left: 50%;
      // top: 50%;
      top: -20px;
      transform: translateX(-50%);
    }
  }
  .btnBlock {
    display: flex;
    @media screen and (min-width: 769px) {
      position: absolute;
      right: 8rem;
    }
    @media screen and (max-width: 768px) {
      order: 3;
      align-self: flex-end;
    }
    & > :not(:first-child) {
      margin-left: 1rem;
    }
  }
}
.nav-link {
  display: flex;
  align-items: center;
  line-height: 50%;
  font-size: 11px;
  font-family: Poppins;
  color: black;
  padding-right: 0px;
}
.nav-link {
  display: flex;
  align-items: center;
  line-height: 50%;
  font-size: 11px;
  font-family: "Poppins", sans-serif !important;
  color: black;
  padding-right: 0px;
}
.nav-item {
  /* border-bottom: 4px solid white;
  border-top: 4px solid white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 5px; */
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 5.2rem;
}
.blueBtn {
  background-color: #2b5aa1 !important;
  color: white !important;
  font-size: 0.9rem;
  padding: 0.6rem 1.5rem !important;
  border-radius: 8px !important;
}
.greyBtn {
  background: #f2efee !important;
  font-family: "Inter", sans-serif;
  font-weight: 900 !important;
  color: #191b1f !important;
  font-size: 0.9rem;
  padding: 0.6rem 1.5rem !important;
  border-radius: 8px !important;
}


.navbar {
  // responsive at 980
  position: relative;
  height: 9rem;
  width: 100%;
  background-color: white;
  position: relative;
  .links {
    position: absolute;
    left: 3rem;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    @media screen and (max-width: 1220px){
     left: 4rem; 
    }
    @media screen and (max-width: 1100px){
      left: 2rem;
      gap: 0rem; 
     }
    .linkItem {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
    }
  }
  .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -2rem;
    background: #ffffff;
    box-shadow: 0px 4px 25px 5px rgba(133, 132, 132, 0.25);
    border-radius: 32px;
    width: 215px;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    img {
      width: 15 5px;
      height: 100px;
      margin-top: 1rem;
    }
    @media screen and (max-width:972px) {
      left: 61%;
      width: 185px;
      height: 170px;
      img {
        width: 12 3px;
        height: 90px;
        margin-top: 1rem;
      }
    }
    // @media screen and(max-width: 844px) {
    //   width: 150px;
    //   height: 170px;
    //   img {
       
    //     height: 76px;
    //     margin-top: 0rem;
    //   }
    // }
  }
  .btnBlock {
    position: absolute;
    right: 2rem;
    @media screen and (max-width: 877px) {
      right: 0rem;
      gap: 0rem;
    }
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: row;
    gap: 1rem;
    .button {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      width: 120px;
      height: 49px;
    }
    .signupBtn {
      background-color: lightgray !important;
    }
    .loginBtn {
      background-color: black !important;
      color: white;
    }
  }
  .hamburger {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .navbar {
    height: 6rem;
    .links {
      display: none;
    }
    .logo {
      left: 50%;
      width: 13rem;
      height: 9rem;
      img {
        width: 9rem;
        height: 6rem;
      }
    }
    @media screen and (max-width:370px) {
      .logo {
        width: 11rem;
        height: 9rem;
        img {
          width: 7rem;
          height: 5rem
        }
      }
    }
    .btnBlock {
      display: none;
    }
    .hamburger {
      display: block;
      position: absolute;
      right: 2rem;
      top: 20%;
      cursor: pointer;
    }
  }
}
.responsive {
  position: fixed;
  //   position: absolute;
  top: 0;
  z-index: 100000;
  //   position: relative;
  height: 100vh;
  width: 100vw;
  display: block;
  .responsive_navbar {
    position: absolute;
    z-index: 10000;
    background-color: white;
    width: 98vw;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    box-shadow: 0px 4px 25px 5px rgba(133, 132, 132, 0.25);
    border-radius: 0px 0px 32px 32px;
    .responsive_logo {
      
      img {
        width: 10rem;
      }
    }
    .btnBlock {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      .button {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        width: 110px;
        height: 40px;
      }
      .signupBtn {
        background-color: lightgray !important;
      }
      .loginBtn {
        background-color: black !important;
        color: white;
      }
    }
    .links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      .linkItem {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
      }
    }
    .hr {
      border-top: 1px solid lightgray;
      width: 90%;
    }
  }
  .backdrop {
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
  }
}
.secondBlock {
  // width: 100vw;
  padding: 0rem 3rem;
  img {
    width: 350px;
    height: 160px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  // .left,
  .right {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 40vw;
    height: 20rem;
    @media screen and (max-width: 768px) {
      width: 30rem;
      height: 20rem;
    }
    @media screen and (max-width: 487px) {
      width: 95vw;
    }
    // flex: 1;
    // & > :not(:first-child) {
    //   margin-top: 1.5rem;
    // }
    // svg {
    //   width: 5rem;
    //   height: 5rem;
    // }
  }
  .left {
    // padding: 5rem 0rem;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 550px) {
      width: 90vw;
      height: auto;
    }
    @media screen and (max-width: 470px) {
      img {
        width: 92vw !important;
        height: auto !important;
      }
      :global {
        .signupBtn {
          width: 6rem !important;
          height: 2rem !important;
          font-size: 0.6rem !important;
        }
      }
    }
    img {
      width: 30rem;
      height: 17rem;
    }
    .btn {
      align-self: center;
    }
  }
  // .right {
  //   flex: 1;
  // }
}

.helpBlock {
  .title {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
  }
  .content {
    display: flex;
    justify-content: space-around;
    padding: 2rem 1rem;
    .helpList {
      // display: flex;
      // flex-direction: column !important;
      // gap: 1rem;
      width: 100% !important;
      flex-wrap: wrap !important;
      justify-content: center;
      gap: 4rem !important;
      :global {
        .specificCard {
          // border: 1px solid red !important;
          width: 23rem !important;
        }
        @media screen and (max-width: 413px) {
          .specificCard {
            width: 94vw !important;
            .description {
              .amplify-text {
                width: 93vw !important;
                margin: auto;
              }
            }
          }
        }
      }
    }
  }
}
.helpItem {
  width: 17rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    height: 7rem;
    width: 7rem;
  }
  .title {
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 32px;
  }
  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    /* or 178% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #535353;
  }
  .btnContainer {
    &::after {
      content: "";
      display: block;
      width: 100%;
      border: 1px dashed #2b55a1;
      margin-top: 0.3rem;
      line-height: 2rem;
    }
  }
  .btn {
    background: #ffcc04;
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    padding: 0rem 0.7rem;
    /* identical to box height */
    svg {
      width: 0.8rem;
      height: 0.8rem;
    }
    cursor: pointer;
    color: #000000;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    & > :not(:first-child) {
      margin-left: 0.2rem;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  justify-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 22rem;
  padding: 1.5rem;
  .description {
    // font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    width: 70%;
    img {
      width: 3rem;
      height: 3rem;
      background-position: center center;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 0.5rem;
      // align-items: center;
      .name {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        padding: 0;
      }
      .post {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #535353;
      }
    }
  }
}

.cardBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 2rem;

  .arrowBtn {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    padding: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffcc04;
    cursor: pointer;
  }
  .list {
    display: flex;
    & > :not(:first-child) {
      margin-left: 2rem;
    }
  }
}
.cardBlockContainer {
  width: 100vw;
  .list {
    display: flex;
    gap: 15px;
    flex-direction: row;
    width: 92vw;
    padding: 0rem 0.5rem;
    height: 18rem;
    align-items: center;
    overflow: hidden;
    @media screen and (max-width: 480px) {
      min-height: 22rem;
    }
    @media screen and (max-width: 367px) {
      min-height: 25rem;
    }
    :global {
      @media screen and (max-width: 480px) {
        .review {
          width: 70vw !important;
          .review_content_text {
            width: 100% !important;
            .amplify-text {
              width: 100% !important;
            }
          }
        }
      }
      @media screen and (max-width: 347px) {
        // .review_image {
        //   width: 0.5rem !important;
        //   height: 0.5rem !important;
        // }
        .nameDetail {
          gap: 0.9rem !important;
          .amplify-text {
            line-height: 0.9rem !important;
          }
        }
      }
    }
  }
}
.footer {
  // background-color: #f2efee;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;
  width: 100%;
  .firstItem {
    position: relative;
    width: 100% !important;

    @media screen and (min-width: 769px) {
      height: 22rem;
      // background: url("../../assets/curveRectangular.png");
      .background {
        // position: absolute;
        img {
          width: 100%;
          height: 22rem;
        }
        z-index: 0;
      }
    }

    .innerFirst {
      .authLogo {
        margin-right: 6rem;
        img {
          height: 220px;
          margin-top: -5rem;
        }
        @media screen and(max-width: 1085px) {
          margin-right: 2rem;
        }
        @media screen and(max-width: 1021px) {
          margin-right: 0rem;
          img {
            height: 180px;
            margin-top: -5rem;
          }
        }
      }
      // @media screen and(max-width:957px) {
      //   .arrowIcon{
      //     height: 70px;
      //   }

      // }

      .joinBtn {
        padding: 0.3rem 1.5rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        @media screen and (max-width: 1068px) {
          padding: 0.2rem 0rem;
          width: 156px;
        }
      }
      @media screen and (min-width: 769px) {
        position: absolute;
        // top: 10%;
        left: 0;
        top: 0;
        width: 100%;
      }
      @media screen and (max-width: 768px) {
        background-color: #2b55a1;
        flex-direction: column;
        gap: 2rem;

        .bottomImg {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100vw;
          .vector {
            height: 160px;
          }
          .authLogo {
            img {
              margin-top: 1rem;
              height: 170px;
            }
          }
        }
      }
      @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column !important;
      }

      height: inherit;
      z-index: 1;
      margin-top: 1rem;
      // background-color: #2b55a1;
      padding: 1rem 3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .content {
        color: #fff;
        .title {
          font-weight: 600;
          font-size: 30px;
        }
        .list {
          .item {
            font-weight: 400;
          }
        }
        @media screen and (min-width: 501px) {
          width: 30rem;
        }
      }
    }
  }
  .secondItem {
    background-color: #ffff;
    // padding: 4rem 4rem 0rem 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    @media screen and (max-width: 880px) {
      display: flex;
      flex-direction: column !important;
      gap: 1.9rem;
      // & > :not(:first-child) {
      //   margin-top: 1rem;
      // }
      .divider {
        border: 1px solid #b7b7b7;
        width: 90%;
      }
      .right {
        align-self: center;
        text-align: center;
        margin-bottom: 0rem;
        width: 100%;
        color: #b7b7b7;
        // margin-bottom: -2rem;
        // border: 1px solid red;
      }
    }
    img {
      width: 9rem;
      height: 6rem;
    }
    .content {
      // align-self: flex-end;
      margin: 2rem 3rem 0rem;
      width: 100%;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-weight: 400;
      gap: 1rem;
      @media screen and (max-width: 600px) {
        align-self: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 2rem;
        .item {
          // border: 1px solid red;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 0.6rem;
          div {
            text-align: center;
          }
        }
      }
      ul {
        list-style: none;
        & li:not(:first-child) {
          margin-top: 10px;
        }
      }
    }
    .right {
      align-self: flex-end;
      margin-bottom: 5px;
    }
  }
}

.blueBtn {
  background-color: #2b5aa1 !important;
  color: white !important;
}

.cardBlockContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  .arrowBtn {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    padding: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffcc04;
    cursor: pointer;
  }
  :global {
    .amplify-text {
      font-family: "Raleway", sans-serif !important;
    }
  }
}

.listCard {
  .arrowBtn {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    padding: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffcc04;
    cursor: pointer;
  }
}
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.react-horizontal-scrolling-menu--wrapper {
  flex-wrap: wrap;
  justify-content: center;
}

.react-horizontal-scrolling-menu--scroll-container {
  flex-basis: 100%;
  flex-shrink: 0;
}

/* Change position of container so arrows will be at top/bottom */
.react-horizontal-scrolling-menu--scroll-container {
  order: -1; /* order: 1; for top position */
}

// secondBlock
.secondBlock {
  display: flex;
  padding: 4rem 3rem;
  align-items: center;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 0.6rem;
    justify-content: center;
  }
  .blueLogo {
    width: 23.5vw;
    object-fit: contain;
    margin: 0px 35px 0px 35px;
  }
}

.cardBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  // @media screen and (max-width: 768px) {
  //   display: flex;
  //   flex-direction: column;
  // }
  padding: 1rem 4rem;
}
.textBlue {
  color: #2b55a1;
}
.expertise {
  background-color: #f2efee;
  border: 1px solid #f2efee;
  padding: 3rem 1rem;
  @media screen and (max-width: 490px) {
    padding: 3rem 0.4rem;
  }
  padding-bottom: 5rem;
  .title {
    margin-left: 0.8rem;
  }
  .cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  // clip-path: polygon(
  //   0 0,
  //   5% 0,
  //   8% 34%,
  //   84% 34%,
  //   89% 73%,
  //   100% 73%,
  //   100% 85%,
  //   100% 100%,
  //   85% 100%,
  //   15% 100%,
  //   0 100%,
  //   0% 85%
  // );

  // clip-path: polygon(
  //   0 0,
  //   12% 0,
  //   17% 6%,
  //   75% 6%,
  //   88% 13%,
  //   100% 13%,
  //   100% 85%,
  //   100% 100%,
  //   85% 100%,
  //   15% 100%,
  //   0 100%,
  //   0% 85%
  // );
  @media screen and (min-width: 1001px) {
    clip-path: polygon(
      0 0,
      4% 0,
      10% 4%,
      83% 4%,
      88% 9%,
      100% 9%,
      100% 85%,
      100% 100%,
      85% 100%,
      15% 100%,
      0 100%,
      0% 85%
    );
  }

  padding-top: 5rem;
}

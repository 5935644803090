.expertiseCard {
  position: relative;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.13));
  width: 380px;
  height: 457.94px;
  //   padding: 2rem;
  background-color: #ffff;
  border-radius: 1.8rem;
  overflow: hidden;
  @media screen and (max-width: 375px) {
    width: 98vw;
    min-height: 80vh;
    // max-width: fit-content;
  }
  .content {
    width: 380px;
    @media screen and (max-width: 375px) {
      width: 90vw;
      height: auto !important;
    }
    height: 457.94px;
    position: absolute;
    padding: 2rem;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .image {
      img {
        width: 54px;
        height: 75px;
      }
    }
    .title {
      color: #191b1f;
      font-weight: 700;
      font-size: 22px;
    }
    .description {
      font-family: "Raleway";
      font-weight: 400;
      font-size: 18px;
      color: #535353;
    }
  }
  .triangle {
    z-index: 0;
    right: 0;
    bottom: -10px;
    position: absolute;
    background-color: #f2efee;
    width: calc(380px / 1.8);
    height: calc(457.94px / 2.5);
    clip-path: polygon(66% 0, 100% 0, 100% 100%, 0% 100%);
  }
}

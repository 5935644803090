.personCard {
  font-family: "Raleway", sans-serif;
  // background: #ffffff;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  width: 100%;
  gap: 5rem;
  overflow: hidden;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  // flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    gap: 1rem;
  }
  @media screen and (max-width: 580px) {
    gap: 0rem;
  }
  .titleImg {
    width: 37%;
    // background-position: center center;
    height: 25rem;
    width: 100%;
    flex-basis: 100%;
    flex: 1;

    img {
      height: 25rem;
      border-radius: 20px;
      object-fit: cover;
      width: 100%;
    }
    @media screen and (min-width: 1864px) {
      img {
        object-fit: contain;
      }
    }
    @media screen and (max-width: 1444px) {
      height: 20rem;
      img {
        height: 20rem;
      }
    }
    @media screen and (max-width: 1214px) {
      height: 14rem;
      img {
        height: 14rem;
      }
    }
    @media screen and (max-width: 950px) {
      height: 11rem;
      img {
        height: 11rem;
      }
    }
    @media screen and (max-width: 768px) {
      height: 24rem;
      img {
        height: 24rem;
        object-fit: cover;
      }
    }
    @media screen and (max-width: 580px) {
      height: 18rem;
      img {
        height: 18rem;
        object-fit: cover;
        border-radius: 0;
      }
    }
    @media screen and (max-width: 448px) {
      height: 12rem;
      img {
        height: 12rem;
      }
    }
  }
  .content {
    width: 58%;
    padding: 1rem;
    display: flex;
    background: white;
    border-radius: 20px;
    flex-direction: column;
    justify-content: space-between;
    min-width: 10rem;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    @media screen and (max-width: 580px) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .description {
      // min-height: 13rem;
    }
    .author {
      margin-top: 1.5rem;
      .name {
        font-family: "Poppins";
        font-weight: 700;
      }
      .post {
        font-family: "Poppins";
        font-weight: 400;
      }
    }
  }
}

// first block
.firstBlock {
  font-family: "Poppins", sans-serif !important;

  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: 768px) {
  }
  .card {
    background: #2b55a1;
    color: #ffff;
    border-radius: 1rem;
    padding: 3.5rem 2rem;
    height: 100%;
    // max-height: 32rem;
    @media screen and (max-width: 1024px) {
      max-height: 100%;
    }
    .title {
      font-size: 38px;
      font-weight: bold;
    }
    .description {
      margin-top: 1.2rem;
      font-family: "Raleway";
      font-weight: 500;
      line-height: 25px;
    }
  }
  .image {
    height: 100%;

    .firstImage {
      width: 100%;
      // height: 25rem !important;
      height: 100%;
      // max-height: 32rem;
    }
    .secondImage {
      width: 100%;
      // height: 25rem !important;
      height: 100%;
      // max-height: 32rem;
      @media screen and (max-width: 1024px) {
        max-height: 100%;
      }
    }
  }
}

// certifications
.certifications {
  background-color: #f2efee;
  .inner {
    .innerMost {
      padding-left: 3rem;
      padding-right: 3rem;
      padding-bottom: 2rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      background-color: #fff;
      @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
      }
      .imageBlock {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 30px;
        @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: column;
        }

        .awsCertified {
          object-fit: contain;
          height: 105px;
          width: 175px;
        }
        .CISA {
          width: 185px;
          height: 105px;
          object-fit: contain;
        }
        .CISSP {
          width: 185px;
          object-fit: contain;
          height: 105px;
        }
        .securityAdministrator {
          object-fit: contain;
          width: 185px;
          height: 105px;
        }
        .microsoftSecurity {
          object-fit: contain;
          height: 99px;
          width: 185px;
        }
      }
    }
    @media screen and (min-width: 1025px) {
      clip-path: polygon(
        50% 0%,
        100% 0,
        100% 35%,
        100% 30%,
        96% 30%,
        89% 100%,
        20% 100%,
        0 100%,
        0% 35%,
        0 0
      );
    }
  }

  .title {
    font-size: 38px;
    color: #191b1f;
    font-weight: 700;
  }
  img {
    // width: 14rem;
    // height: 6rem;
    width: 244px;
    height: 101px;
  }
}
.cardBlock {
  background: #f2efee;
  padding: 3rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 1rem;
  // align-items: center;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    & > :not(:first-child) {
      margin-top: 2rem;
    }
  }
  @media screen and (min-width: 601px) {
    & > :not(:first-child) {
      margin-left: 2rem;
      align-self: stretch;
    }
  }
}

.coachCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  .content {
    border-radius: 1rem;
    background: #2b55a1;
    color: #fff;
    border: 1px solid #2b55a1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
    gap: 1rem;
    width: 20rem;
    height: 23rem;
    .image {
      img {
        width: 8rem;
        height: 8rem;
      }
    }
    .title {
      width: 80%;
      font-weight: bold;
      font-size: 1.3rem;
      text-align: center;
    }
    .description {
      text-align: center;
      font-weight: 300;
      font-size: 0.9rem;
    }
  }
}

.signup {
  // height: 100vh;
  // overflow: hidden;
  overflow: hidden !important;
  .signupLogo {
    align-self: center;
    text-align: center;
    margin-top: 2rem;
  }
  .row {
    position: relative;
    overflow: hidden;
    width: 100%;
    .left {
      overflow: hidden;
      max-height: 100vh;
      min-height: 100vh;
      height: 100vh;
      position: fixed; /* Fixed Sidebar (stay in place on scroll) */
      z-index: 1; /* Stay on top */
      top: 0; /* Stay at the top */
      left: 0;
      width: 30vw;
    }

    .leftTriangle {
      z-index: 1000;
      position: absolute;
      // bottom: 1;
      bottom: -2rem;
      right: -2rem;
      svg {
        // width: 10rem;
        height: 30vh;
      }
    }
    .leftSide {
      overflow: hidden;

      z-index: 100;
      height: 100vh;
      background: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      .logo {
        width: 8.7rem;
        height: 7rem;
        margin-bottom: 1rem;
      }
      hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;

        border-top: 1px solid #ffcc04;
        width: 40%;
      }
      .content {
        text-align: center;
        color: #ffff;

        .title {
          font-size: 1.5rem;
          font-weight: 800;
        }
        .description {
          font-size: 0.8rem;
          font-weight: 400;
        }
        .guide {
          font-size: 0.8rem;
          .text1 {
            font-weight: 600;
          }
          .text2 {
            color: #ffcc04;
            font-weight: 600;
          }
        }
      }
    }
  }
  :global {
    .nextBtn {
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      .spinner-border {
        margin-right: 1rem;
      }
    }
  }
}
.usernameInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .append {
    margin-left: 1rem;
    color: gray;
    font-size: 0.8rem;
  }
}
.usernameBadge {
  background-color: #abc3f9;
  font-size: 0.7rem;
  color: #19326e;
  padding: 0.3rem 0.5rem;
  border-radius: 1rem;
  font-weight: bold;
  cursor: pointer;
}

.userForm2 {
  .badgeList {
    margin-top: 0.6rem;
    & span:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
  :global {
    .policy {
      label {
        width: 27rem;
      }
    }
    .pass {
      border: 1px solid #ced4da;
    }
  }
}
.required {
  color: red;
  padding-left: 2px;
  padding-top: 0.5rem;
}
.errorInput {
  border: 1px solid red;
}
.errorSelect {
  select {
    border: 1px solid red;
  }
}
.textRed {
  color: red;
}
.passwordErr {
  width: 25rem;
}
.greyText {
  color: #f2efee;
}

.rightSide {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  padding: 0.8rem 4rem;
  @media screen and (max-width: 768px) {
    width: 100vw !important;
    padding: 0.3rem 0.8rem;
  }
  .header {
    font-size: 0.8rem;
    align-self: flex-end;
    padding: 1rem;
    a {
      text-decoration: none;
      color: #191b1f;
      font-weight: 700;
    }
  }
  .rightContent {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    // overflow-y: scroll;
    .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
      display: none;
    }
    .form {
      .title {
        font-size: 1.7rem;
        font-weight: 600;
        margin-bottom: 0.8rem;
      }
    }
    .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      .steps {
        font-size: 0.9rem;
        font-weight: bold;
      }
      .next {
        flex: 1;
        margin-left: 1rem;
      }
    }
    .backBtn {
      width: 5rem;
    }
    .backDarkBlue {
      background: #19326e !important;
      color: #ffff;
    }
    .backLightBtn {
      border: 1px solid #c9cccf;
      color: black;
      font-weight: 700;
    }
  }
}

.linkedIn {
  .text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }
}

.aboutUs {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  //   background-color: #f2efee;
  .first {
    padding-top: 3rem;
    // background-color: #fff;
    // padding: 2rem 0rem;
    // clip-path: polygon(
    //   50% 0%,
    //   100% 0,
    //   100% 35%,
    //   100% 94%,
    //   96% 94%,
    //   88% 100%,
    //   20% 100%,
    //   0 100%,
    //   0% 35%,
    //   0 0
    // );
  }
}
